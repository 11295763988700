import IEnumDetails from "./helpers/IEnumDetails";

interface ISectionTypes {
    TEXT: IEnumDetails;
    IMAGE: IEnumDetails;
    LINK: IEnumDetails;
    Q_AND_A_FORMAT: IEnumDetails;
    CHECKBOX: IEnumDetails;
}

const sectionTypes: ISectionTypes = {
    TEXT: { name: "Text", label: "Text", value: 0 },
    IMAGE: { name: "Image", label: "Image", value: 1 },
    LINK: { name: "Link", label: "Link", value: 2 },
    Q_AND_A_FORMAT: { name: "Q_AND_A_FORMAT", label: "Sub-section", value: 3 },
    CHECKBOX: { name: "Checkbox", label: "Checkbox", value: 4 },
};


export default sectionTypes;