import { createBrowserRouter, Navigate } from "react-router-dom";

import featureSettings from "../../common/settings/features/featureSettings";

import CookiePolicy from '../../../features/legal/CookiePolicy';
import Challenges from '../../../features/guest/Challenges/Challenges';
import Details from '../../../features/guest/Challenges/Details';
import GuestRoot from "../../../features/guest/home/GuestRoot";
import HomeGuest from '../../../features/guest/home/HomeGuest';
import Login from '../../../features/user/Login';
import Logout from '../../../features/user/Logout';
import NotFound from "../NotFound";
import PrivacyPolicy from '../../../features/legal/PrivacyPolicy';
import Signup from '../../../features/user/Signup';
import TermsAndConditions from '../../../features/legal/TermsAndConditions';
import Videos from '../../../features/guest/Videos/Videos';


export const guestRouter = createBrowserRouter([
    {
        path: "/",
        element: <GuestRoot />,
        errorElement: <NotFound />,
        children: [
            { index: true, element: <HomeGuest /> },
            { path: "login", element: featureSettings.LOGIN_ENABLED ? <Login /> : <Navigate to="/" /> },
            { path: "logout", element: <Logout /> },
            { path: "signup", element: (featureSettings.LOGIN_ENABLED
                && featureSettings.REGISTRATION_ENABLED) ? <Signup /> : <Navigate to="/" /> },
            { path: "learn", element: <Videos /> },
            { path: "cookie-policy", element: <CookiePolicy /> },
            { path: "privacy-policy", element: <PrivacyPolicy /> },
            { path: "terms-and-conditions", element: <TermsAndConditions /> },
            { path: "challenges", element: <Challenges /> },
            { path: "challenges/:id", element: <Details /> },
        ]
    }
]);