import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Alert from "react-bootstrap/esm/Alert";

import featureSettings from "../../app/common/settings/features/featureSettings";
import { useStore } from "../../app/stores/store";

import logo from "../../logo.png";
import "./login.css";

function Login () {
        
    const navigate = useNavigate();

    const { userStore, navigationStore } = useStore();

    let validateAllBtnRef:any = null;

    useEffect(() => {

        navigationStore.setUserIsOnLoginScreen(true);
        
        setTimeout(() => {

            if(validateAllBtnRef)
            {
                validateAllBtnRef.click();
            }
        }, 250);

        return () => {
            navigationStore.setUserIsOnLoginScreen(false);
        };
    });


    return (
        <div className="login-container">
            <div className="form rounded-corners shadow">
                <Link to="/"><img src={logo} alt="Overcome Restart" /></Link>
                <Formik
                    initialValues={{ email: "", password: "", error: null }}
                    onSubmit={values => userStore.login(values, (url: string) => navigate(url))}
                    validationSchema={Yup.object({
                        email: Yup.string().required().email(),
                        password: Yup.string().required()
                    })}
                >
                    {({ handleSubmit, isSubmitting, errors, isValid, dirty, validateForm }) => (
                        <Form onSubmit={handleSubmit} autoComplete="off" className="form-fields">
                            
                            <div className="form-group">
                                <label>Email</label>    
                                <Field type="email" name="email" />
                                <ErrorMessage name="email" render={() => <Alert variant="danger">{errors.email}</Alert>} />
                            </div>

                            <div className="form-group">
                                <label>Password</label>    
                                <Field type="password" name="password"/>
                                <ErrorMessage name="password" render={() => <Alert variant="danger">{errors.password}</Alert>} />
                            </div>

                            <ErrorMessage
                                name="error"
                                render={() => <Alert variant="danger">{errors.error}</Alert>}
                            />

                            <button style={{display:"none"}}
                                type="button"
                                ref={input => {
                                    // assigns a reference so we can trigger it later
                                    validateAllBtnRef = input;
                                }}
                                onClick={() => validateForm().then(() => false)}
                            >
                                Validate All
                            </button>

                            <button type="submit" className="primary" disabled={!isValid || !dirty || isSubmitting}>
                                {isSubmitting ? "Logging in..." : "Log in"}
                            </button>
                            {featureSettings.REGISTRATION_ENABLED
                                && <Link to="/signup" className="login-alternative">Or create account</Link>}
                            {userStore.logInError && <Alert variant="danger">Sorry, we couldn't log you in.<br />Please check your details are correct.<br />Or contact us for help accessing your account.</Alert>}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default observer(Login);