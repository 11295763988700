import React from "react";
import { Link } from "react-router-dom";

import featureSettings from "../../../app/common/settings/features/featureSettings";

function TopNavMenuItems() {
    return (
        <ul>
            <li><Link className="nav-link" to="/learn">Learn</Link></li>
            <li><Link className="nav-link" to="/challenges">Challenges</Link></li>
            {featureSettings.LOGIN_ENABLED &&
                <li><Link className="nav-link" to="/login">Log in</Link></li>}
        </ul>
    );
}

export default TopNavMenuItems;