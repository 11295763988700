interface IFeatureSettings {
    LOGIN_ENABLED: boolean;
    REGISTRATION_ENABLED: boolean;
}

const featureSettings: IFeatureSettings = {
    LOGIN_ENABLED: process.env.REACT_APP_FEATURES_LOG_IN_ENABLED === "true",
    REGISTRATION_ENABLED: process.env.REACT_APP_FEATURES_REGISTRATION_ENABLED === "true"
}

export default featureSettings;

