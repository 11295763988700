import React from "react";

import { observer } from "mobx-react-lite";

import { Field } from "formik";

interface IProps {
    label: string;
    name: string;
    classes?: string;
}

const CheckboxField = (props: IProps) => {

  return (
    <div className={`form-group checkbox-container ${props.classes}`}> 
        <Field {...props} type="checkbox" />
        <label className="m-2">{props.label}</label> 
    </div> 
  );
};

export default observer(CheckboxField);