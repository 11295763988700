import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import { FieldArray, FieldArrayRenderProps, Form, FormikProps } from "formik";
import { FaArrowDown, FaArrowUp, FaMinus, FaPlus } from "react-icons/fa";

import IJournalEntry from "../../../../app/models/journal-entries/IJournalEntry";

import enumHelper from "../../../../app/common/enums/helpers/enumHelper";
import sectionTypes from "../../../../app/common/enums/sectionTypes";
import { useStore } from "../../../../app/stores/store";

import CheckboxField from "../../../../app/layout/form-fields/CheckboxField";
import CustomButton from "../../../../app/layout/common-components/CustomButton";
import CustomButtonHeading from "../../../../app/layout/common-components/CustomButtonHeading";
import CustomImage from "../../../../app/layout/images/CustomImage";
import DeleteButton from "../../../../app/layout/form-fields/DeleteButton";
import FileUploadFieldDropzone from "../../../../app/layout/form-fields/FileUploadFieldDropzone";
import TextInputField from "../../../../app/layout/form-fields/TextInputField";
import SelectField from "../../../../app/layout/form-fields/SelectField";
import TextAreaField from "../../../../app/layout/form-fields/TextAreaField";
import TextHiddenField from "../../../../app/layout/form-fields/TextHiddenField";
import ISection from "../../../../app/models/sections/ISection";

import "./../journal-entries.css";

const JournalEntryFormInner : React.FC<FormikProps<IJournalEntry>> = (props) => {

    const navigate = useNavigate();

    const { values, isSubmitting, isValid, dirty } = props;

    const { fileStore, journalEntryStore } = useStore();

    const markupFormattingTips = `Enter your text here. (See formatting tips below)

Heading
---------

- Bullet point

**Bold**

[Link](www.overcomerestart.com)`;

    const handleConfirmDelete = async () => {
        await journalEntryStore.deleteJournalEntry(values.id!);

        navigate("/journal-entries");
    }

    const handleRemoveItem = (s: ISection, arrayHelpers: FieldArrayRenderProps, index: number) => {
        arrayHelpers.remove(index);

        if (enumHelper.areEqual(s.sectionType, sectionTypes.IMAGE.value) && s.fileName) {
            fileStore.delete("journal-entries", s.fileName);
        }
    };

    return (
        <Form className="custom-form journal-entries">
            <TextInputField label="Title" name="name" type="text" errorMessage="Name is required" />

            <FieldArray
                name="sections"
                render={arrayHelpers => (
                    <div>
                        {values.sections && values.sections.length
                            ? <div>

                                {values.sections.map((s, index) => (
                                <div key={index}>
                                    <SelectField name={`sections.${index}.sectionType`} label="Type" options={enumHelper.enumToOptionList(sectionTypes)} />

                                    {enumHelper.areEqual(s.sectionType, sectionTypes.IMAGE.value)
                                        && <>
                                        {s.fileName
                                            ? <div className="image-section">
                                                <CustomImage src={s.fileName} alt="" />
                                            </div>
                                            : <>
                                                <FileUploadFieldDropzone
                                                    fileUriField={`sections.${index}.fileUri`}
                                                    fileExtensionField={`sections.${index}.fileExtension`}
                                                    uploadFile={(file: Blob | null) => fileStore.uploadBlob("journal-entries", file)}
                                                    uploading={fileStore.loading}
                                                />

                                                <TextHiddenField name={`sections.${index}.fileUri`} />
                                                <TextHiddenField name={`sections.${index}.fileExtension`} />
                                            </>
                                            }
                                        </>
                                    }

                                    {enumHelper.areEqual(s.sectionType, sectionTypes.LINK.value)
                                        && <TextInputField name={`sections.${index}.name`} label="Link text" type="text" placeholder="e.g. Overcome Restart" />}

                                    {enumHelper.areEqual(s.sectionType, sectionTypes.Q_AND_A_FORMAT.value) 
                                        && <TextInputField name={`sections.${index}.name`} label="Heading" type="text" />}

                                    {enumHelper.areEqual(s.sectionType, sectionTypes.LINK.value)
                                        && <TextInputField name={`sections.${index}.url`} label="Link" type="url" placeholder="e.g. https://overcomerestart.com" />}

                                    {enumHelper.areEqual(s.sectionType, sectionTypes.CHECKBOX.value)
                                        && <div className="d-flex">
                                            <CheckboxField name={`sections.${index}.checkboxValue`} label="" classes="mb-5" />
                                            <TextInputField
                                                name={`sections.${index}.name`} 
                                                label="" 
                                                type="text" 
                                                placeholder="Checkbox question" 
                                                errorMessage="Checkbox question is required" 
                                                className="flex-field"
                                            />
                                        </div>}

                                    {!(enumHelper.areEqual(s.sectionType, sectionTypes.LINK.value)
                                            || enumHelper.areEqual(s.sectionType, sectionTypes.IMAGE.value)
                                            || enumHelper.areEqual(s.sectionType, sectionTypes.CHECKBOX.value))
                                        && <TextAreaField name={`sections.${index}.text`} label="Text" rows={7} placeholder={markupFormattingTips} />}

                                    <div className="field-list-buttons">
                                        <div>
                                            <CustomButtonHeading onClick={() => handleRemoveItem(s, arrayHelpers, index)} classes="m-2">
                                                <FaMinus />
                                            </CustomButtonHeading>

                                            <CustomButtonHeading onClick={() => arrayHelpers.insert(index + 1, "")}>
                                                <FaPlus />
                                            </CustomButtonHeading>
                                        </div>

                                        <div>
                                            {index > 0 &&
                                                <CustomButtonHeading onClick={() => arrayHelpers.swap(index, index - 1)} classes="m-2">
                                                    <FaArrowUp />
                                                </CustomButtonHeading>}

                                            {values.sections && index < values.sections.length - 1 && 
                                                <CustomButtonHeading onClick={() => arrayHelpers.swap(index, index + 1)}>
                                                    <FaArrowDown />
                                                </CustomButtonHeading>}
                                        </div>
                                    </div>
                                
                                </div>))}

                                <CustomButton
                                        text={isSubmitting ? "Saving..." : "Save"}
                                        type="submit"
                                        disabled={!isValid || !dirty || isSubmitting}
                                        classes="submit-button primary"
                                    />
                            </div>
                            : (<CustomButton
                                text="Add a section"
                                type="button"
                                classes="primary"
                                onClick={() => arrayHelpers.push("")} />)}
                    </div>)} />

                    {values.id && <DeleteButton handleConfirmDelete={handleConfirmDelete} />}
        </Form>);
}

export default observer(JournalEntryFormInner);