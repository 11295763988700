import React from 'react';

import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { FaTwitter, FaYoutube } from "react-icons/fa";

import featureSettings from '../../../app/common/settings/features/featureSettings';

function HomeGuest() {
  return (
      <div className="guest-home-container">
        <div className="welcome-section">
            <div className="tagline">Let's support each other to beat low self-confidence, fear of failure and procrastination</div>
            <div className="get-started">
                <Link to="/learn" className="call-to-action">
                    <span>start </span>
                    <span>learning</span>
                </Link>
                <Link to="/challenges" className="call-to-action">
                    <span>choose a </span>
                    <span>challenge</span>
                </Link>
                {featureSettings.REGISTRATION_ENABLED && <Link to="/signup" className="call-to-action">
                    <span>create your </span>
                    <span>account</span>
                </Link>}
            </div>
            <div className="social-media-links">
                <span>Follow us for helpful videos and self-help resources</span>
                <div className="icons">
                <a href="https://twitter.com/OvercomeRestart"><FaTwitter /></a>
                <a href="https://www.youtube.com/channel/UCIvdXmVMd6fXSoTORMX3PHw"><FaYoutube /></a>
                </div>
            </div>
        </div>
      </div>
  );
}

export default observer(HomeGuest);