import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import dompurify from "dompurify";
import { marked } from "marked";

import enumHelper from "../../../../app/common/enums/helpers/enumHelper";
import sectionTypes from "../../../../app/common/enums/sectionTypes";
import { useStore } from "../../../../app/stores/store";

import CustomImage from "../../../../app/layout/images/CustomImage";
import Loading from "../../../../app/layout/Loading";
import MainSection from "../../../../app/layout/common-components/MainSection";
import NoResultsFound from "../../../../app/layout/NoResultsFound";

import "./details.css";
import "./../journal-entries.css";

const JournalEntryDetails = () => {
    
    const params = useParams();

    const { journalEntryStore } = useStore();

    useEffect(() => {
        journalEntryStore.loadJournalEntry(params.id!);
    }, [journalEntryStore, params.id]);

    return (<>
        {journalEntryStore.loading && <Loading />}

        {!journalEntryStore.journalEntry
            && <NoResultsFound message="We couldn't find the journal entry you were looking for. Please search again with different terms or contact support for help." />}

        {journalEntryStore.journalEntry
            && 
            <MainSection
                name={journalEntryStore.journalEntry.name || ""}
                previousSectionLink="/journal-entries"
                updateItemLink={`/journal-entries/${journalEntryStore.journalEntry.id}/edit`}
                classes="journal-entries"
            >
                <>
                    {journalEntryStore.journalEntry.sections?.map(s => (
                        <div className="mb-3">
                            {(enumHelper.areEqual(s.sectionType, sectionTypes.Q_AND_A_FORMAT.value))
                                && <div className="custom-label">{s.name}</div>}

                            {s.text && <div dangerouslySetInnerHTML={{__html: dompurify.sanitize(marked.parse(s.text))}}></div>}

                            {s.url && <a href={s.url} target="_blank" rel="noreferrer">{s.name || s.url}</a>}

                            {enumHelper.areEqual(s.sectionType, sectionTypes.CHECKBOX.value) 
                                && <div className="d-flex">
                                    <div className="form-group checkbox-container me-2">
                                        <input
                                            type="checkbox" 
                                            checked={s.checkboxValue} 
                                            disabled 
                                            className="form-check-input align-middle" />
                                    </div>
                                    <div className="custom-label">{s.name}</div>
                                </div>}

                            {s.fileName && <div className="image-section">
                                <CustomImage src={s.fileName}  alt="" />
                            </div>}
                        </div>
                    ))}
                </>
            </MainSection>}
    </>);
}

export default observer(JournalEntryDetails);